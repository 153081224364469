<template lang="pug">
old-tooltip(:icon="getIcon()" :size="size" tooltip-position="bottom")
  template(#content v-if="label || $slots?.default")
    slot
      .warning(:style="`max-width: ${maxWidth}px;`") {{ label }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import OldTooltip from "~/components/ui/tooltip/OldTooltip.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "OldWarning",
  components: {
    OldTooltip,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<'info'|'question'>,
      default: 'info',
    },
    size: {
      type: String as PropType<'small'|'default'>,
      default: 'default',
    },
    maxWidth: {
      type: Number,
      default: 420,
    },
  },
  setup(props) {

    function getIcon() {
      switch(props.type) {
        case 'info': return UiIconNames.Icon_InfoNew;
        case 'question': return UiIconNames.Icon_Question;
      }
    }

    return {
      getIcon,
    }
  }
})
</script>

<style scoped lang="scss">
.warning {
  max-width: 420px;
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}
</style>
